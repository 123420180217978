<template>
  <v-bottom-navigation
    :app="toggle"
    :input-value="toggle"
    active-class="active secondary--text"
    class="m-nav black"
  >
    <v-btn
      v-for="(page, key) in pages"
      :key="'btn-'+key"
      :to="page.route"
      exact
      icon
      tile
      class="btn"
    >
      <v-icon>{{ page.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<style lang="scss" scoped>
  .m-nav {
    height: calc(56px + env(safe-area-inset-bottom)) !important;
    padding: 0 0 env(safe-area-inset-bottom) env(safe-area-inset-left) !important;
  }
  .m-nav .btn {
    min-width: auto !important;
    width: 100%;
    height: 100%;
  }
  .m-nav .btn:hover::before {
    opacity: 0 !important;
  }
</style>

<script>

  export default {
    props: {
      toggle: {
        type: Boolean,
        default: true
      },
      pages: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
    }),
    watch: {
    },
    methods: {
      
    },
  }
</script>